export default {
  Auth: {
    mandatorySignIn: false,
    region: process.env.REGION,
    userPoolId: process.env.USER_POOL_ID,
    identityPoolId: process.env.IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: process.env.API_NAME,
        endpoint: process.env.API_URL,
        region: process.env.REGION,
      },
    ],
  },
  Storage: {
    region: process.env.REGION,
    bucket: process.env.BUCKET,
    identityPoolId: process.env.IDENTITY_POOL_ID
  },
}
