/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import Auth from '@aws-amplify/auth'
import { setUser } from './src/utils/auth'

import Amplify, { Auth, API } from 'aws-amplify'
import config from './src/config/aws-exports'

Amplify.configure(config)
API.configure(config)

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      const userInfo = {
        ...user.attributes,
        username: user.username,
      }
      setUser(userInfo)
    })
    .catch(err => {
      window.localStorage.setItem('gatsbyUser', null)
    })
}
